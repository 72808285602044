.welcomeForm {
    width: 340px;
    margin-left: auto;
    margin-right: auto;
}

.welcomeForm p.error {
    margin-top: -10px;
    font-size: 15px;
    color: red;
}

.welcomeForm input.input {
    display: block;
    width: 100%;
    height: 50px;
    border: 0px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
    background-color: #edf2f5;
}

.welcomeForm input.file {
    display: block;
    cursor: pointer;
    width: 100%;
    border: 0px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
    background-color: #edf2f5;
}

.welcomeForm textarea.textarea {
    display: block;
    width: 100%;
    height: 100px;
    resize: none;
    border: 0px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
    background-color: #edf2f5;
}

.welcomeForm .btnForm {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    margin-top: 20px;
    color: #edf2f5;
    background-color: #4a7d8d;
}

.welcomeSpan {
    width: 320px;
    color: rgb(133, 133, 133);
    font-size: 14px;
    margin: 14px 0 18px;
}

.welcomeForm .selectInput {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    padding: 6px;
    border: 0px;
    margin-bottom: 10px;
    color: rgb(70, 70, 70);
    background-color: #edf2f5;
    cursor: pointer;
}

.authDataWrapper {
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    background-color: rgb(210, 227, 225);
}

.authDataWrapper p {
    margin: 0px;
    margin-bottom: 10px;
}

.authDataWrapper input {
    margin: 0px !important;
    margin-top: 10px !important;
}

.inputPhoneNumber {
    width: 100% !important;
    height: 50px !important;
    margin-bottom: 10px !important;
}

.marginTop10px {
    margin-top: 10px;
}
