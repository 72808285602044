* {
    box-sizing: border-box;
}

body {
    overflow-y: scroll;
    /* background-image: url("./images/bg.jpg");
    background-attachment: fixed; */
}

.container {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
}

.wrapper_left {
    border-radius: 10px;
    width: 280px;
    float: left;
}

.wrapper_right {
    width: 700px;
    float: right;
}

.displayBlock {
    display: block;
}

.float_right {
    float: right;
}

.welcome_left {
    width: 640px;
    float: left;
}

.welcome_left img {
    width: 100%;
}

.welcome_right {
    width: 340px;
    float: right;
}

.welcomeForm {
    width: 340px;
}

.welcomeForm input.input {
    display: block;
    width: 100%;
    height: 50px;
    border: 0px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
    background-color: #edf2f5;
}

.welcomeForm .btnForm {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    margin-top: 20px;
    color: #edf2f5;
    background-color: #4a7d8d;
}

.welcomeSpan {
    width: 100%;
    color: rgb(133, 133, 133);
    font-size: 14px;
    margin: 14px 0 18px;
}

.bord {
    width: 100%;
    border-radius: 6px;
    /* background-color: rgb(210, 227, 225); */
    border: 22px solid #f3f3f3;
    padding: 20px;
}

.overflowHidden {
    overflow: hidden;
}

.cursorPointer {
    cursor: pointer;
}
