.banner {
    width: 700px;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.banner img {
    width: 700px;
    height: 280px;
    object-fit: cover;
}
