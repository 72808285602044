.wrapper_right__left {
    width: 270px;
    float: left;
}

.indicators {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1px;
}

.indicators .cabinet_item {
    background-color: rgb(210, 227, 225);
    padding: 10px;
    height: 70px;
    border-radius: 5px;
}

.applications {
    margin-top: 10px;
    padding: 20px;
    /* background-color: #f3f3f3; */
    border: 2px solid #f1f1f1;
    border-radius: 5px;
}

.applications .applications_title {
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d1d1d1;
}

.board {
    width: 100%;
    /* background-color: #f5f5f5; */
    border: 2px solid #f1f1f1;
    border-radius: 5px;
    padding: 20px;
    float: right;
}
