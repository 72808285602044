.wrapperProduct {
    width: 100%;
    margin-bottom: 40px;
    overflow: hidden;
}

.wrapperProduct_left {
    width: 260px;
    float: left;
}

.wrapperProduct_left img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 20px;
}

.wrapperProduct_right {
    width: 420px;
    float: right;
}
