.itemBlock {
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
}

.itemBlock .itemBlock_left {
    width: 200px;
    padding: 20px;
    float: left;
}

.itemBlock .itemBlock_left img {
    width: 160px;
    height: 160px;
    object-fit: cover;
}

.itemBlock .itemBlock_right {
    width: 496px;
    min-height: 204px;
    border-left: 2px solid #f1f1f1;
    padding: 20px;
    float: right;
}

.itemBlock .itemBlock_right .title {
    padding-bottom: 4px;
}

.itemBlock .itemBlock_right .title a {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: #4a7d8d;
}

.itemBlock .itemBlock_right .specification {
    padding-bottom: 10px;
}

.itemBlock .itemBlock_right .item {
    width: 180px;
    display: inline-block;
}

.itemBlock .itemBlock_right .description {
    padding-top: 10px;
}

.itemBlock .itemBlock_right .notInfo {
    font-size: 13px;
    color: #666666;
}
