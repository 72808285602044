.formControl.error input,
.formControl.error textarea,
.formControl.error select {
    border: 1px solid red !important;
    margin-bottom: -4px !important;
}

.formControl.error span {
    font-size: 14px;
    color: red;
}
