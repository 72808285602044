.wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    color: #c9c9c9;
    background-color: #f5f5f5;
}

.link {
    text-decoration: none;
    font-size: 16px;
    color: #4a7d8d;
}
