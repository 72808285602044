.wrapper {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    height: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    font-size: 15px;
    padding: 0 20px;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.wrapper span {
    font-size: 14px;
    margin: 0 10px;
    color: rgb(80, 136, 110);
}
