.wrapper {
    display: inline-block;
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    width: 226px;
    height: 354px;
    margin-right: 11px;
    margin-bottom: 10px;
    overflow: hidden;
}

.wrapper:nth-child(3n) {
    margin-right: 0px;
}

.wrapper img {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-bottom: 1px solid #f1f1f1;
}

.wrapper .productCompany {
    margin-top: 4px;
    font-size: 13px;
    color: #727272;
    text-align: center;
}
.wrapper .productTitle {
    text-align: center;
    height: 36px;
    padding: 0px 10px;
}

.wrapper .productTitle p {
    margin: 0px;
}

.wrapper .productPrice {
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
}

.wrapper .price {
    font-weight: 700;
}

.wrapper .summ {
    font-size: 17px;
    font-weight: 700;
}

.wrapper .btnAddProduct {
    border: 1px solid #f1f1f1;
    width: calc(100% - 20px);
    margin-top: 13px;
    margin-left: 10px;
    height: 28px;
    cursor: pointer;
    border-radius: 4px;
}

.wrapper .btnEditProduct {
    border: 1px solid #f1f1f1;
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-left: 10px;
    height: 28px;
    cursor: pointer;
    border-radius: 4px;
}

.wrapper .btnDeleteProduct {
    border: 1px solid #f1f1f1;
    width: 75px;
    margin-top: 10px;
    margin-left: 10px;
    height: 28px;
    cursor: pointer;
    border-radius: 4px;
}

.wrapper .inStockProduct {
    /* border: 1px solid #f1f1f1; */
    width: 110px;
    line-height: 27px;
    margin-top: 10px;
    margin-right: 14px;
    height: 28px;
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    float: right;
}

.wrapper .productQuantity {
    margin-top: 8px;
    overflow: hidden;
}

.wrapper .quantity {
    display: inline-block;
    min-width: 24px;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
}

.wrapper .productQuantity_left {
    max-width: 80px;
    margin-top: 1px;
    margin-left: 10px;
    float: left;
}
.wrapper .productQuantity_left .less {
    border: 2px solid #f1f1f1;
    cursor: pointer;
    border-radius: 4px;
}
.wrapper .productQuantity_left .more {
    border: 2px solid #f1f1f1;
    cursor: pointer;
    border-radius: 4px;
}
.wrapper .productQuantity_right {
    max-width: 132px;
    margin-right: 10px;
    float: right;
}

.switch {
    position: relative;
    display: block;
    float: right;
    margin-top: 5px;
    width: 34px;
    height: 20px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 0px;
    background-color: rgb(255, 255, 255);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #4a7d8d;
}

input:focus + .slider {
    box-shadow: 0 0 1px #4a7d8d;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
