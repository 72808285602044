.mainHeadWrapper {
    box-sizing: border-box;
    width: 100%;
    height: 130px;
    padding-top: 10px;
}

.mainHeadWrapper .left {
    width: 300px;
    float: left;
}

.mainHeadWrapper .left img {
    width: 220px;
    margin-top: 20px;
    margin-left: 32px;
}

.mainHeadWrapper .center {
    margin-top: 10px;
    float: left;
}

.mainHeadWrapper .center .popularQueries {
    font-size: 15px;
}

.mainHeadWrapper .center .popularQueries a {
    text-decoration: none;
    color: #4a7d8d;
}

.mainHeadWrapper .center form {
    width: 700px;
    margin-top: 24px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.mainHeadWrapper .center .keyItem {
    padding-left: 8px;
}

.mainHeadWrapper .center .searchInput {
    box-sizing: border-box;
    width: 480px;
    height: 40px;
    font-size: 16px;
    padding: 13px;
    border: 2px solid #eaeaea;
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    float: left;
}

.mainHeadWrapper .center .selectInput {
    box-sizing: border-box;
    width: 120px;
    height: 40px;
    font-size: 16px;
    padding: 10px;
    border: 2px solid #eaeaea;
    border-right: 0;
    cursor: pointer;
}

.mainHeadWrapper .center .btnSearch {
    box-sizing: border-box;
    width: 100px;
    height: 40px;
    border: 0px;
    padding: 0px;
    cursor: pointer;
    font-size: 16px;
    color: #f5f5f5;
    background-color: #4a7d8d;
    border-left: 1px solid rgb(201, 201, 201);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    float: right;
}

.mainHeadWrapper .right {
    width: 150px;
    margin-top: 14px;
    float: right;
}

.mainHeadWrapper .right .callMe {
    font-size: 14px;
    color: rgb(133, 133, 133);
}

.mainHeadWrapper .right .phone {
    font-size: 17px;
    margin: 6px 0px;
    text-decoration: none;
    color: #4a7d8d;
}

.mainHeadWrapper .right .mail {
    font-size: 15px;
    color: rgb(133, 133, 133);
}
