.form {
    width: 100%;
    height: 80px;
    padding: 20px;
    background-color: #f5f5f5;
}

.form div {
    display: inline-block;
    width: 230px;
    float: left;
    /* width: 100%;
    padding: 20px;
    background-color: #f5f5f5; */
}

.form select {
    width: 229px;
    height: 40px;
    border: 0px;
    font-size: 14px;
    padding: 5px;
    margin-right: 1px;
    cursor: pointer;
}

.form button {
    width: 156px;
    height: 40px;
    color: white;
    border: 0px;
    float: right;
    background-color: #4a7d8d;
    cursor: pointer;
}

span.error {
    font-size: 15px;
    color: red;
}
