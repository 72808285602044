.wrapper {
    border: 2px solid #f1f1f1;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
}

.textWrapper {
    display: inline-block;
    width: 209px;
    height: 28px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #6d939e;
}
