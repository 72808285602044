.wrapperSupplier {
    width: 100%;
    margin-bottom: 80px;
    overflow: hidden;
}

.supplierCover {
    position: relative;
    z-index: -1;
}

.supplierCover img {
    width: 100%;
    height: 250px;
    opacity: 0.8;
    object-fit: cover;
    border-radius: 10px;
}

.wrapperSupplier_left {
    width: 240px;
    float: left;
}

.wrapperSupplier_left img {
    width: 220px;
    height: 220px;
    object-fit: cover;
    margin-top: -100px;
    margin-left: 20px;
    border-radius: 20px;
}

.wrapperSupplier_right {
    width: 440px;
    float: right;
}
