.wrapper {
    border: 2px solid #f1f1f1;
    border-radius: 20px;
}

/* .wrapper img {
    width: 100%;
    height: 160px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
} */

.wrapper .select_wrapper {
    width: 100%;
    /* height: 160px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    background-color: #f5f5f5;
}

.wrapper .select_wrapper select {
    width: 100%;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
    padding: 10px;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    background-color: #f1f1f1; */
}

.wrapper .boxForBtn {
    width: 100%;
    padding: 15px 20px;
    border-bottom: 2px solid #f1f1f1;
}

.wrapper .boxForBtn button {
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
    color: #f1f1f1;
    border: 0px;
    border-radius: 5px;
    background-color: #4a7d8d;
}

.wrapper .top {
    width: 100%;
    height: 60px;
    padding: 15px 20px;
    border-bottom: 2px solid #f1f1f1;
}

.wrapper ul {
    list-style-type: none;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 4px 20px;
}

.wrapper ul li {
    list-style-type: none;
    margin: 20px 0;
    overflow: hidden;
}

.wrapper ul li .text {
    width: 196px;
    line-height: 24px;
    float: right;
    color: #4a7d8d;
}

.wrapper ul li img {
    width: 28px;
    height: 28px;
}
